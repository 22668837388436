.blog-container {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin: 0 auto;
  max-width: 100%; /* Set to 100% width for flexibility */
  width: 90%; /* Added width control to allow margins on either side */
  background-color: #ecdcec; /* Light Lavender background */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

h2 {
  color: #471dbf; /* Deep Blue for main headings */
  text-align: center; /* Center the main heading */
  margin-bottom: 20px;
}

.blog-container div {
  margin-bottom: 20px;
  padding: 15px;
  border-bottom: 1px solid #8c99ec; /* Soft Blue for div borders */
  background-color: #ffffff; /* White background for each blog post */
  border-radius: 5px; /* Softer corners for blog posts */
}

h3 {
  font-size: 1.5rem;
  color: #dd6fde; /* Vibrant Pink for subheadings */
  margin-bottom: 10px;
}

p {
  font-size: 1.2rem;
  color: #333; /* Dark text for readability */
}

a {
  color: #54ddcd; /* Turquoise for links */
  text-decoration: none;
}

a:hover {
  text-decoration: underline; /* Underline on hover for links */
}

