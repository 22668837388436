.footer {
  background-color: #522c7f; /* Dark Pink background */
  padding: 0; /* Remove extra padding since we're placing the tagline on the banner */
  text-align: center;
  border-top: 2px solid #dd6fde; /* Vibrant Pink border */
  width: 100%;
  box-sizing: border-box;
}

.footer-banner-container {
  position: relative;
  width: 100%;
  height: auto;
}

.footer-banner {
  width: 100%;
  height: auto;
  max-height: 100px; /* Adjust based on your banner size */
  object-fit: cover;
}

.footer-tagline {
  position: absolute;
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Offset to truly center */
  font-size: 1.5rem;
  color: #471dbf; /* Dark Purple from the navbar hover */
  margin: 0;
  padding: 0;
  font-weight: bold;
}







