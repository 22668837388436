.header {
  padding: 0; /* Remove padding to allow the banner to fill the entire header */
  text-align: center;
  height: 150px; /* Set consistent height for the header */
  border: none; /* Remove any border */
  width: 100%;
  box-sizing: border-box;
}

.header-banner-container {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

.header-banner {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image fills the container without distortion */
  border: none; /* Remove any borders */
}





  

  

  