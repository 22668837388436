/* About Page Styles */
.about-container {
  background-color: #ecdcec; /* Light Lavender background to match the blog */
  color: #333; /* Dark gray for readability */
  padding: 40px;
  margin: 20px auto;
  width: 80%;
  border-radius: 8px; /* Soft corners for a welcoming look */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.about-title {
  font-size: 2.5rem;
  color: #471dbf; /* Deep Blue for the title */
  text-align: center;
  margin-bottom: 20px;
  font-family: 'Georgia', serif; /* Professional, classic font */
}

.about-content {
  font-size: 1.2rem;
  color: #dd6fde; /* Vibrant Pink for paragraph text */
  line-height: 1.6;
  font-family: 'Arial', sans-serif; /* Clean, readable font */
  text-align: justify;
}

body {
  background-color: #ecdcec; /* Light Lavender background to match the overall site */
  color: #333; /* Dark gray for readability */
}

h1, h2, h3 {
  color: #471dbf; /* Deep Blue for headings */
}

a {
  color: #54ddcd; /* Turquoise for links */
}

button {
  background-color: #ffcc00; /* Mustard yellow for buttons */
  color: #fdf5e6; /* Eggshell text for button contrast */
  padding: 10px 20px;
  border-radius: 5px;
}

button:hover {
  background-color: #dd6fde; /* Vibrant Pink on hover */
  cursor: pointer;
}

.footer {
  background-color: #ecdcec; /* Light Lavender for a clean footer */
  color: #471dbf; /* Deep Blue for the footer text */
}



