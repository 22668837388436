.contact-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Vertically center the content */
  text-align: center;
}

.contact-title {
  font-size: 2rem;
  color: #471dbf; /* Deep Blue color */
  margin-bottom: 20px;
}

.contact-link {
  font-size: 1.5rem;
  color: #471dbf; /* Deep Blue color for the link */
  text-decoration: none;
  padding: 10px 20px;
  border: 2px solid #471dbf;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.contact-link:hover {
  background-color: #dd6fde; /* Vibrant Pink on hover */
  color: #fff; /* White text on hover */
}


